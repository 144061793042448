module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","title":"Presidium Health - Home-based Healthcare","description":"Presidium Health provides home-based care services to patients.","keywords":"home-based healthcare, value-based healthcare, patient care, telemedicine"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Presidium Health","short_name":"presidiumhealth","start_url":"/","background_color":"#ffffff","theme_color":"#0098da","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9efec830a8d2aed318b1c866c91a7b65"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
